import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import windowLogo from '../images/windows_logo.svg'
import appleLogo from '../images/apple_logo.svg'
import linuxLogo from '../images/cof_black_hex.svg'
import GetAppIcon from '@material-ui/icons/GetApp'
const Download = () => {
    return(
        <div>
            <Layout>
                <Seo title="Download" description="BricoBrowser associated with Ricta Technologies" />
                <div className="bg-bgColor">
                    <div className="w-4/5 md:w-full max-w-screen-xl m-auto py-10">
                        <div className="text-xl sm:text-3xl font-bold my-4 text-mainColor mb-4">Download BricoBrowser</div> 
                        <div className="md:flex items-center justify-evenly">
                            <div className="flex flex-col mx-2 mb-4 p-4 shadow-md rounded-md border-2">
                                <div className="flex items-center justify-center">
                                    <img src={windowLogo}/>
                                </div>
                                <p className="font-semibold py-2">BricoBrowser for Windows</p>
                                <span className="text-sm mb-1">Requires Windows 7 or newer</span>
                                <button className="bg-mainColor text-white font-semibold px-4 p-2"><GetAppIcon  className="mr-1"/>Download for Windows</button>
                            </div>
                            <div className="flex flex-col mx-2 mb-4 p-4 shadow-md rounded-md border-2">
                                <div className=" m-auto">
                                    <img src={appleLogo}/>
                                </div>
                                <p className="font-semibold py-2 ">BricoBrowser for Mac</p>
                                <span className="text-sm mb-1">Requires 10.9 or newer</span>
                                <button className="bg-mainColor text-white font-semibold px-4 p-2"><GetAppIcon  className="mr-1"/>Download for Mac</button>
                            </div>
                            <div className="flex flex-col mx-2 mb-4 p-4 shadow-md rounded-md border-2">
                                <div className=" m-auto">
                                    <img src={linuxLogo}/>
                                </div>
                                <p className="font-semibold py-2">BricoBrowser for Linux</p>
                                <span className="text-sm mb-1">Community Edition</span>
                                <button className="bg-mainColor text-white font-semibold px-4 p-2"><GetAppIcon className="mr-1"/>Download for Linux</button>
                            </div>  
                        </div>
                        <div className="my-4 text-sm">
                            <p>Current Version BricoBrowser 1.1.0</p>
                        </div>

                    </div>
                </div>
            </Layout>
        </div>
    )
}
export default Download